.App-session-body-table-title-container {
	display: flex;
	height: 10%;
	width: 100%;
	flex-direction: row;
}

.App-session-body-table-title-content {
  border-collapse: collapse;
  padding: 0.5rem;
  text-align: left;
  background-color: #F4F4F4;
  border-right: 1px solid #DEDFE0;
  height: 35px;
  top: 0;
  position: sticky;
  display: flex;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: #1F2532;
}

.App-session-body-table-title-content-detail {
  margin: auto 10px;
}

#App-session-body-table-title-date {
	width: 15%;
}

#App-session-body-table-title-topic-name {
	width: 40%;
}

#App-session-body-table-title-speaker {
	width: 20%
}

#App-session-body-table-title-attendance {
	width: 20%
}

#App-session-body-table-title-edit-icon{
  width: 5%;
  border : none
}

@media only screen and (max-width: 1500px) {
  .App-session-body-table-title-content {
    height: 20px;
    font-size: 12px;
  }
}
