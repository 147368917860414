.App-session-body-role-to-vote {
  display: flex; 
  position: fixed; 
  z-index: 1;
  margin: auto; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.App-session-body-role-to-vote-modal-content {
  position: relative;
  background-color: #ffffff ;
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #888;
  width: 30%;
  height: 30%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: black;
  float: right;
  margin : auto;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.App-session-body-role-to-vote-modal-header {
  background-color: #EDEEF0;
  height: 20%;
  display: flex;
  flex-direction: row;
}

.App-session-body-role-to-vote-modal-body-header-title {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: #161616;
  margin: auto;
  text-align: left;
  width: 80%;
}

.App-session-body-role-to-vote-modal-body {
  height: 60%;
  display: flex;
  margin: auto;
  flex-direction: column;
  border-bottom: 1px solid #969AA3;
}

.App-session-body-role-to-vote-modal-body-label-content {
  height: 40%;
  margin: 10px 20px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.3px;
  color: #444F5C;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.App-session-body-role-to-vote-modal-body-url-content {
  height: 40%;
  display: flex;
  flex-direction: row;
}

.App-session-body-role-to-vote-modal-body-url-content-url {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.3px;
  background-color: #EDEEF0;
  border-radius: 2px;
  width: 75%;
  margin: 20px 0px 20px 30px;
  justify-content: center;
  padding: 10px;
  overflow: hidden;
}

.App-session-body-role-to-vote-modal-body-url-content-copy {
  width: 20%;
  margin: 20px 20px 20px 0px;
  display: flex;
  padding: 5px;
  justify-content: center;
}

.App-session-body-role-to-vote-modal-footer {
  height: 20%;
}

.App-session-body-role-to-vote-footer-done {
  border: 1px solid #969AA3;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 71px;
  height: 32px;
  margin: 10px;
  cursor: pointer;
}

.App-session-body-role-to-vote-footer-done-label {
  margin: auto;
}

@media only screen and (max-width: 1500px) {
  .App-session-body-role-to-vote-modal-content {
    width: 30%; 
    height: 40%;
  }

  .App-session-body-role-to-vote-modal-body-label-content {
    font-size: 12px;
  }

  .App-session-body-role-to-vote-modal-body-header-title {
    font-size: 16px;
  }

  .App-session-body-role-to-vote-modal-body-url-content-url {
    margin: 10px 0px 10px 30px;
    font-size: 10px;
  }
}