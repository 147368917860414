.App-body {
	height: 93%;
	width: 100%;
	display : flex;
	flex-direction: column;
}

.App-sesion-table-container {
	margin : auto;
	width : 90%;
	display: flex;
	flex-direction: column;
	height: 80%;
}

.App-session-pagination-container {
	height: 10%;
	width: 100%;
	border-top : 1px solid grey;
}

.App-session-table-title-container {
	width: 100%;
	height: 10%;
}

.App-session-table {
	width : 100%;
	height: 90%;
}

.icon-left-arrow-thick {
	cursor: pointer;
}

.icon-right-arrow-thick {
	cursor: pointer;
}

.App-session-table-title {
	float : left;
	width : 40%;
	height: 100%;
	display: flex;
	flex-direction: row;
}

.App-session-table-title-actions {
	float: right;
	width : 30%;
	height: 100%;
}

.App-session-table-title-button-holder {
	width : 100%;
	margin: auto;
	height: 100%;
}

.App-session-table-title-button-roll-to-vote {
	margin: 20px 0px;
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 17px;
	text-align: center;
	letter-spacing: 0.3px;
	color: #161616;
	background: #FFFFFF;
	border: 1px solid #969AA3;
	box-sizing: border-box;
	border-radius: 2px;
	align-items: center;
	padding: 9px 17px;
	cursor: pointer;
	float: right;
	display: flex;
}

.App-session-table-title-button-create-new {
	margin: auto;
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 17px;
	text-align: center;
	letter-spacing: 0.3px;
	color: #161616;
	background: #FFFFFF;
	border: 1px solid #969AA3;
	box-sizing: border-box;
	border-radius: 2px;
	align-items: center;
	padding: 9px 17px;
	cursor: pointer;
}

.App-session-table-title-button-create-new-container {
	display: flex;
	flex-direction: row;
}

.App-session-table-title-button-create-new-enabled {
	margin: auto;
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 17px;
	text-align: center;
	letter-spacing: 0.3px;
	color: #FFFFFF;
	background: #0060FF;
	border: 1px solid #969AA3;
	box-sizing: border-box;
	border-radius: 2px;
	align-items: center;
	padding: 9px 17px;
	cursor: pointer;
}

.App-session-table-prev-arrow {
	height: 100%;
	margin: auto 0px;
	display: flex;
	cursor: pointer;
}

.App-session-table-next-arrow {
	height: 100%;
	margin: auto 0px;
	display: flex;
	cursor: pointer;
}

.App-session-table-title-name {
	width : 50%;
	height: 100%;
	display: flex;
	margin: auto 0px auto 0px;
	font-family: "Proxima Nova Semibold";
	font-size: 26px;
	font-weight: 600;
	letter-spacing: 0.3px;
	line-height: 29px;
}

.App-session-table-title-create-session {
	width : 100%;
	height: 100%;
	display: flex;
	margin: auto 10px;
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;	
	color: #969AA3;
}

#session-name-text-area {
	height: 60%;
	width: 100%;
	margin: 10px;
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	background: #F4F5F6;	
	border-radius: 2px;
}

.App-session-table-title-dot {
	width : 25%;
	height: 100%;
	display: flex;
	margin: auto 0px auto 5px;
}

.App-session-table-title-content {
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: #000000;
	margin: auto;
	cursor: default;
}

.App-session-table-title-status-content {
	display: flex;
	flex-direction: row;
	float: left;
	width: 100%;
}

.App-session-table-title-status-container {
	display: flex;
	width: 85%;
}

.App-session-table-title-status {
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 17px;
	letter-spacing: 0.3px;
	color: #1F2532;
	margin: auto 10px auto 10px;
	cursor: default;
	width: 85%;
}

.App-session-table-title-status-dot-container {
	display: flex;
	width: 15%;
}

.App-session-table-title-status-dot {
	background: #D4D5D6;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	margin: auto 0px auto 0px;
	margin: auto;
}

.App-session-table-title-status-completed-dot {
	background: green;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	margin: auto 0px auto 0px;
	margin: auto;
}

.create-close {
  	color: red;
  	float: right;
  	font-size: 33px;
  	font-weight: bold;
  	margin: auto 10px;
}

.create-close-label {
  margin: auto;
}

.create-close:hover,
.create-close:focus {
  	color: #000;
  	text-decoration: none;
  	cursor: pointer;
}

.combo-box-wrapper {
	margin: auto;
	font-family: "Proxima Nova Semibold";
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.3px;
	line-height: 29px;
	height: 100%;
	display: flex;
}

.chevron-down-div {
	margin: auto;
}

#combo-down-arrow{
	margin: auto;
}

.App-session-table-title-dropdown {
	margin: auto;
	text-overflow: ellipsis;
	font-size: 15px;
	width: 100%;
	display: flex;
	flex-direction: row;
}

.App-session-table-title-dropdown div:first-child {
	height: 100%;
	width: 100%;
}

.App-session-table-create-session {
	display: flex;
	width: 15%;
	margin-left : 10px;
}

.App-session-table-create-session-button {
	margin: auto;
	display: flex;
	width: 40%;
	height: 40%;
	background: #4285F4;
	border-radius: 50%;
	cursor: pointer;
}

.App-session-table-create-session-plus {
	margin: auto;
}

@media only screen and (max-width: 1500px) {

	.App-session-table-title-container {
		height: 15%;
	}

	.App-session-table-title {
		height: 100%;
	}

	.combo-box-wrapper {
		margin: auto;
		font-family: "Proxima Nova Semibold";
		font-size: 13px;
		font-weight: 200;
		letter-spacing: 0.3px;
		line-height: 29px;
		height: 100%;
		width: 100%;
		background: white;
		display: flex;
	}

	.App-session-table-title-button-roll-to-vote {
		font-size: 12px;
	}

	.App-session-table-title-dot {
		width: 15%;
		margin-left: 20px;
	}

	.App-session-table-title-status {
		font-family: Inter;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 17px;
		letter-spacing: 0.3px;
		color: #1F2532;
		margin: auto 10px auto 10px;
		cursor: default;
		width: 85%;
	}
}

@media only screen and (max-width: 1500px) { 
	.App-session-table-title-dropdown div:first-child {
		height: 80%;
	}

	.App-session-table-title-dropdown li {
		font-size: 10px;
	}

	.App-session-table-create-session-button {
		width: 30%;
		height: 30%;
	}

}

@media only screen and (min-width: 1500px) {


	.App-session-table-title {
		height: 100%;
	}

	.App-session-table-title-button-roll-to-vote {
		font-size: 14px;
	}

	.App-session-table-title-dot {
		width: 15%;
		margin-left: 20px;
	}
}
