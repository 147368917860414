.App-session-body-table-edit-form-container{
	display: flex;
	height: 60px;
	border-bottom: 1px solid #E1E1E1;
}

.combo-agent-box-wrapper{
	font-size: 18px;
	font-family: Inter;
	font-weight: 300;
	letter-spacing: 0.3px;
	line-height: 29px;
}

.combo-agent-box-option {
	font-family: "Proxima Nova Semibold";
}

#combo-down-arrow {
	height: 20px;
	margin: auto;
}

.App-session-body-table-edit-form-content{
	padding: 0.5rem;
	border-collapse: collapse;
	text-align: left;
	display: flex;
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 17px;
	letter-spacing: 0.3px;
	color: #1F2532;
}

#topic-name-text-area {
	width: 100%;
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 17px;
	letter-spacing: 0.3px;
}

.speaker-text-area {
	width: 80%;
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 17px;
	letter-spacing: 0.3px;
}

.App-session-body-table-edit-form-text-content-detail {
	margin: auto 10px;
	display: flex;
	width: 100%;
	height: 75%;
}

.App-session-body-table-edit-form-content-detail {
	margin: auto 10px;
	display: flex;
}

.App-session-body-table-edit-form-calender-content-detail {
	display: flex;
	width: 100%;
	height: 75%;
	margin: auto 10px;
}

#App-session-body-table-edit-form-date {
	width: 15%;}

#App-session-body-table-edit-form-topic-name {
	width: 40%;
}

#App-session-body-table-edit-form-speaker {
	width: 20%;
}

#App-session-body-table-edit-form-attendance {
	width: 20%
}

#App-session-body-table-edit-form-tick-icon{
  width: 5%;
}