.App {
  display: flex;
  height : 100vh;
  flex-direction: column;
}

@font-face {
  font-family : "Proxima Nova";
  src : url("https://d36263b6wju30t.cloudfront.net/theme/v1.0.1/fonts/ProximaNova/proxima-nova-regular/proxima-nova-regular.woff2");
}

@font-face{
  font-family : "Proxima Nova Semibold";
  src : url("https://d36263b6wju30t.cloudfront.net/theme/v1.0.1/fonts/ProximaNova/proxima-nova-semibold/proxima-nova-semibold.woff2");
}

.App-body-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}