.App-title-block {
	width : 100%;
  	height : 7%;
  	background-color : #3F4757;
  	display: flex;
  	border-bottom: solid #161616 1px;
}

.App-title-block-title {
	width: 8%;
  	margin: auto;
}

.App-title-block-title-logo {
  	max-height: 100%;
  	max-width: 100%;
}

.App-title-container {
	width: 90%;
	margin: auto;
	display: flex;
	flex-direction: row;
}

.App-title-user-name-container {
	float: left;
	width : 20%;
	margin-left: 70%;
	display: flex;
	flex-direction: row;
}

.App-title-user-name {
	width: 70%;
	display: flex;
}

.App-title-user-name-label {
	margin: auto;
	display: flex;
	color: white;
}

.App-title-logout {
	width: 30%;
	display: flex;
}

.App-title-logout-icon {
	margin: auto;
	color : white;
	cursor: pointer;
}

#sign-out {
	font-size: 30px;
}

@media only screen and (max-width: 1500px) { 
	.App-title-block-title {
		display: flex;
	}

	.App-title-user-name-label {
		font-size: 12px;
		width: 100%;
	}
}