.App-session-completed-container {
	height: 30%;
	background: #1F2532;
	margin : 0 0 10px 0;
}

.App-session-completed-inner-container {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100;
}

.App-session-vote-box {
	width: 15%;
	margin: 10px;
}

.App-session-vote-box-details {
	display: flex;
	flex-direction: row;
	background: #394150;
	border-radius: 5px;
	width: 100%;
	height: 100%;
}

.App-session-vote-rank {
	width: 30%;
	margin: auto 5px;
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 32px;
	letter-spacing: 0.3px;
	color: #FFFFFF;
}

.App-session-vote-box-result-details {
	width: 70%;
	display: flex;
	flex-direction: column;
	margin: auto;
}

.App-session-vote-box-result-wait {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.3px;
	color: #FFFFFF;
	width: 70%;
	display: flex;
	flex-direction: column;
	margin: auto;
}

.App-session-vote-box-result-speaker-name {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.3px;
	color: #FFFFFF;
}

.App-session-vote-box-result-speaker-votes {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 150%;
	letter-spacing: 0.3px;
	color: #FFFFFF;
	opacity: 0.7;
}

.App-session-completed-view-result-container {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 14px;
	text-align: right;
	letter-spacing: 0.3px;
	color: #FFFFFF;
	margin: auto;
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

.App-session-completed-view-result-label {
	margin: auto;
}

.App-session-completed-view-result-chevron {
	margin: auto;
}