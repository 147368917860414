.App-session-body-table-form-container{
	display: flex;
	height: 15%;
	border-top: 1px solid #E1E1E1;
}

.combo-agent-box-wrapper{
	font-size: 16px;
	font-family: Inter;
	font-weight: 300;
	letter-spacing: 0.3px;
	line-height: 29px;
}

.combo-agent-box-option {
	font-family: "Proxima Nova";
}

.App-session-body-table-form-content{
	padding: 1rem;
	border-collapse: collapse;
	text-align: left;
	display: flex;
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 17px;
	letter-spacing: 0.3px;
	color: #1F2532;
	height: 90%;
}

#topic-name-text-area {
	width: 100%;
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 17px;
	letter-spacing: 0.3px;
}

.speaker-text-area {
	width: 80%;
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 17px;
	letter-spacing: 0.3px;
}

.speaker-drop-down {
	width : 70%;
	height: 100%;
	box-sizing: border-box;
	border: 1px solid #969AA3;
	border-radius: 2px;
	color: #161616;
 	font-family: "Inter";
 	font-size: 14px;
 	letter-spacing: 0.3px;
 	line-height: 17px;
}

.App-session-body-table-form-text-content-detail {
	margin: auto 10px;
	display: flex;
	width: 100%;
	height: 75%;
}

.App-session-body-table-form-speaker-content-detail {
	margin: auto;
	display: flex;
	width: 100%;
	height: 75%;

}

.calender-container {
	height: auto;
}

.App-session-body-table-form-content-detail {
	margin: auto 10px;
	display: flex;
}

.App-session-body-table-form-calender-content-detail {
	display: flex;
	width: 100%;
	height: 75%;
	margin: auto;
}

.App-session-body-table-form-speaker-content-detail div:first-child {
	height: 100%;
	width: 90%;
}

#App-session-body-table-form-date {
	width: 15%;
	display: flex;
	height: 50%;
}

#App-session-body-table-form-topic-name {
	width: 50%;
	height: 50%;
}

#App-session-body-table-form-speaker {
	width: 20%;
	height: 50%;;
}

#App-session-body-table-form-attendance {
	width: 20%;
	height: 50%;
}

#App-session-body-table-form-add-icon{
  width: 5%;
  height: 50%;
}

.disabled-add-icon {
	cursor : not-allowed;
}

@media only screen and (max-width: 1500px) {
	.App-session-body-table-form-content {
		margin-top: 10px;
	}


	.App-session-body-table-form-content {
		font-size: 14px;
	}

	.App-session-body-table-form-container{
		display: flex;
		height: 25%;
		border-top: 1px solid #E1E1E1;
	}

	.combo-agent-box-wrapper {
		font-size: 12px;
	}

	.combo-agent-box-option li {
		font-size: 10px;
	}
}

@media only screen and (min-width: 1500px) {
	.App-session-body-table-form-content {
		margin-top: 10px;
	}

	.App-session-body-table-form-container{
		display: flex;
		height: 15%;
		border-top: 1px solid #E1E1E1;
	}

}
