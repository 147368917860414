.App-voting-body {
	height: 93%;
	width: 100%;
	display : flex;
	flex-direction: column;
}

.App-voting-session-title{
	height: 5%;
	width: 100%;
	display: flex;
}

.App-voting-session-table {
	width : 100%;
	height: 90%;
	display: flex;
	flex-direction: column;
	margin: 10px 30px;
}


.App-voting-session-title-label {
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #000000;
	margin: 20px 30px;
}

.App-voting-session-body{
	display: flex;
	flex-direction: column;
	height: 100%;
}