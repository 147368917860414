.App-session-body-table-content-container {
	display: flex;
	width: 100%;
	border-bottom: 1px solid #E1E1E1;
}

.App-session-body-table-content{
	padding: 0.5rem;
	border-collapse: collapse;
	text-align: left;
	height : 30px;
	display: flex;
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 17px;
	letter-spacing: 0.3px;
	color: #1F2532;
	cursor: default;
}

.App-session-body-table-content-detail {
	margin: auto 10px;
}

#App-session-body-table-date {
	width: 15%;
}

#App-session-body-table-topic-name {
	width: 40%;
}

#App-session-body-table-speaker {
	width: 20%;
}

#App-session-body-table-attendance {
	width: 20%
}

#App-session-body-table-edit-icon{
  width: 5%;
}

@media only screen and (max-width: 1500px) {
  .App-session-body-table-content {
    height: 20px;
    font-size: 12px;
  }
}