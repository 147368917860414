.App-session-vote-result-table-container {
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 95%;
  margin: 10px;
}

.App-session-vote-result-table-title-container {
	display: flex;
	height: 15%;
	width: 100%;
	flex-direction: row;
}

.App-session-vote-result-table-title-content {
  border-collapse: collapse;
  padding: 0.5rem;
  text-align: left;
  background-color: #F4F4F4;
  border-right: 1px solid #DEDFE0;
  height: 30px;
  top: 0;
  position: sticky;
  display: flex;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: #1F2532;
}

.App-session-vote-result-table-title-content-detail {
  margin: auto 10px;
}

.App-session-vote-result-table-contents-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70%;
  overflow:scroll;
  border-bottom: 1px solid #blue;
}

.App-session-vote-result-table-content-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.App-session-vote-result-table-content{
  padding: 0.5rem;
  border-collapse: collapse;
  text-align: left;
  height : 30px;
  display: flex;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #1F2532;
  cursor: default;
  border-bottom: 1px solid #E1E1E1;
}

.App-session-vote-result-table-content-detail {
  margin: auto 10px;
}

#App-session-vote-result-table-rank{
  width: 10%;
}

#App-session-vote-result-table-speaker {
  width: 30%;
}

#App-session-vote-result-table-topic {
  width: 50%;
}

#App-session-vote-result-table-count {
  width: 10%;
}

#App-session-vote-result-table-title-rank{
  width: 10%;
}

#App-session-vote-result-table-title-speaker {
  width: 30%;
}

#App-session-vote-result-table-title-topic {
  width: 50%;
}

#App-session-vote-result-table-title-count {
  width :10%
}