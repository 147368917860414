.App-session-vote-result-container{
	display: flex; 
	position: fixed; 
	z-index: 1;
	margin: auto; 
	left: 0;
	top: 0;
	width: 100%; 
	height: 100%; 
	overflow: auto; 
	background-color: rgba(0,0,0,0.4);
}

.App-session-vote-result-modal-content {
  position: relative;
  background-color: #ffffff ;
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #888;
  width: 50%;
  height: 50%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

.App-session-vote-result-header{
	display: flex;
	flex-direction: row;
	background: #EDEEF0;
	border-radius: 2px 2px 0px 0px;
	width: 100%;
	height: 15%;
}

.App-session-vote-result-table {
	width: 100%;
	height: 70%;
}

.App-session-vote-result-footer{
	height: 15%;
	border-top: 1px solid #D4D5D6;
}

.App-session-vote-result-header-label{
	font-family: Proxima Nova;
  	font-style: normal;
  	font-weight: 600;
  	font-size: 21px;
  	line-height: 21px;
  	letter-spacing: 0.3px;
  	color: #161616;
  	margin: auto;
  	text-align: left;
  	width: 80%;
}

.close {
  	color: black;
  	float: right;
 	 margin : auto;
  	font-size: 28px;
  	font-weight: bold;
}

.close:hover,
.close:focus {
  	color: #000;
  	text-decoration: none;
  	cursor: pointer;
}

.App-session-vote-result-footer-done {
  border: 1px solid #969AA3;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 71px;
  height: 32px;
  margin: 10px;
  background: #FFFFFF;
  cursor: pointer;
}

.App-session-vote-result-footer-done-label {
  margin: auto  ;
  font-family: Proxima Nova;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */

letter-spacing: 0.3px;

color: #161616;


/* Inside Auto Layout */

flex: none;
order: 0;
flex-grow: 0;
margin: auto;
}
