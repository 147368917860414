.App-session-body-vote-submit {
	height: 10%;
	display: flex;
	margin: 10px;
}

.App-session-body-vote-submit-button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: #00BFA5;
	border-radius: 2px;
	width: 100px;
	cursor: pointer;
	height: 50%;
	margin: 10px;
}

.App-session-body-vote-submit-button-label {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	/* identical to box height, or 114% */

	text-align: center;
	letter-spacing: 0.3px;
	color: #FFFFFF;


	/* Inside Auto Layout */

	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 0px 10px;
}