.App-session-body-table-contents-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow:scroll;
}

@media only screen and (max-width: 1280px) {
	.App-session-body-table-contents-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		overflow:scroll;
		margin-bottom: 5px;
	}
}