.App-session-body-vote-table-content-container {
	display: flex;
	width: 100%;
	border-bottom: 1px solid #E1E1E1;
}

.App-session-body-vote-table-content{
	padding: 0.5rem;
	border-collapse: collapse;
	text-align: left;
	height : 30px;
	display: flex;
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 17px;
	letter-spacing: 0.3px;
	color: #1F2532;
	cursor: default;
}

.App-session-body-vote-table-content-detail {
	margin: auto 10px;
}

#App-session-body-vote-table-date {
	width: 15%;
}

#App-session-body-vote-table-topic-name {
	width: 35%;
}

#App-session-body-vote-table-speaker {
	width: 25%;
}

#App-session-body-vote-table-vote {
	width : 5%
}
