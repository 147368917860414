.App-add-session-modal {
  display: flex; 
  position: fixed; 
  z-index: 1;
  margin: auto; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.App-add-session-modal-content {
  position: relative;
  background-color: #ffffff ;
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #888;
  width: 30%;
  height: 40%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

.close {
  color: black;
  float: right;
  margin : auto;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.App-add-session-modal-header {
  background-color: #EDEEF0;
  height: 20%;
  display: flex;
  flex-direction: row;
}

.App-add-session-modal-header-title {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: #161616;
  margin: auto;
  text-align: left;
  width: 80%;
}

.App-add-session-modal-body {
  height: 60%;
  width: 100%;
  display: flex;
  margin: auto;
  flex-direction: column;
  border-bottom: 1px solid #969AA3;
}

.App-add-session-modal-label-content {
  float : left;
  margin: auto auto 0 auto;
  width: 80%;
  height: 10%;
  display: flex;
}

.App-add-session-modal-label-content-text {
  width: 90%;
  margin: 0 auto 0 auto;
}

.App-add-session-modal-label-content-text-mandatory {
  color :red
}

.App-add-session-modal-text-area-container {
  height: 60%;
  width: 80%;
  margin: 0 auto auto auto;
  display: flex;
}

.App-add-session-modal-text-area {
  height: 40%;
  width: 90%;
  margin: 0 auto 0 auto;
  background-color: pink;
  display: flex;
  background: #F4F5F6;
  border: none;
  border-radius: 2px;
}

.App-add-session-modal-text-area-error {
  height: 50%;
  width: 90%;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  font-color : red;
}

#App-add-session-modal-text-area-text-error {
  display: flex;
  height: 70%;
  font-family: Inter;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  border: 1px solid red;
  border-radius: 2px;
}

.App-add-session-modal-text-area-error-label {
  margin: 0px;
  height: 30%;
  font-family: Inter;
  font-style: normal;
  display: flex;
  font-size: 10px;
  color: red;
  line-height: 24px
}

.App-add-session-modal-footer-button-container {
  display: flex;
  flex-direction: row;
  float : right;
  margin-right : 20px;
  margin-top: 10px;
}

#App-add-session-modal-text-area-text {
  width: 100%;
  font-family: Inter;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}

.App-add-session-modal-footer {
  height: 20%;
}

.App-add-session-footer-done {
  border: 1px solid #969AA3;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100px;
  height: 40px;
  margin: 10px;
  cursor: pointer;
}

.App-add-session-footer-done-label {
  margin: auto;
}

.App-add-session-footer-save {
  border: 1px solid #969AA3;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100px;
  height: 40px;
  margin: 10px;
  cursor: pointer;
  color: white;
  background-color: #4285F4;
}

.App-add-session-footer-save-label {
  margin: auto;
}

@media only screen and (max-width: 1500px) {
  .App-add-session-modal-label-content {
    margin-bottom: 10px;
  }

  .App-add-session-footer-save {
    height: 30px;
    margin: 5px;
  }

  .App-add-session-footer-done {
    height: 30px;
    margin: 5px;
  }

  .App-add-session-modal-header-title {
    font-size: 18px;
  }

  #App-add-session-modal-text-area-text {
    font-size: 12px;
  }

}