.App-session-body-vote-table-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.App-session-body-vote-table-innner-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}