.App-login-container {
	display : flex;;
	width: 100%;
	height: 100%;
	flex-direction: row;
}

.App-login-form-container {
	display: flex;
	background-color: #00BFA5;
	width: 50%;
	height: 100%;
	flex-direction: column;
}

.App-login-form-header {
	margin : 5% 20%;
}

.App-login-form-body {
	margin: 12% 20%;
	width: 80%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.App-login-form-body-header {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 16px;
	letter-spacing: 0.3px;
	color: #FFFFFF;
	height: 10%;	
}

.App-login-form-body-contents{
	height: 90%;
	display: flex;
	flex-direction: column;
}


.App-login-form-body-email-container {
	height: 15%;
	margin: 30px 10px 10px 0px;
	width: 75%;
	display: flex;
	flex-direction: column;
}

.App-login-form-body-email-label {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.3px;
	color: #FFFFFF;
	height: 20%;
}

.App-login-form-body-email-text-area {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.3px;
	color: black;
	height: 50%;
	width: 90%;
	border: 1px solid #00BFA5;
	box-sizing: border-box;
	border-radius: 5px;
	margin: auto 0px;
}

.App-login-form-body-password-label {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.3px;
	color: #FFFFFF;
	height: 20%;
}

.App-login-form-body-password-text-area {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.3px;
	color: black;
	height: 50%;
	width: 90%;
	border: 1px solid #00BFA5;
	box-sizing: border-box;
	border-radius: 5px;
	margin: auto 0px;
}

.App-login-form-body-password-container {
	height: 15%;
	margin: 10px 10px 10px 0px;
	width: 75%;
	display: flex;
	flex-direction: column;
}

.App-login-form-body-login-button-container {
	height: 15%;
	margin: 10px 10px 10px 0px;
	width: 75%;
	display: flex;
	flex-direction: column;
}

.App-login-form-body-login-button {
	width : 90%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	border: none;
	background: #0060FF;
	border-radius: 2px;
	margin: auto 0px;
	cursor: pointer;
}

.App-login-form-body-login-label {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0.3px;
	color: #FFFFFF;
}

.App-login-form-body-login-label-disabled {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0.3px;
	color: #FFFFFF;
	cursor: not-allowed;
}

.App-login-form-logo-container {
	display: flex;
	width : 50%;
}


.App-login-logo-div {
	display: flex;
	margin: 200px 100px;
}