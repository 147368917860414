.App-session-body-vote-completed{
	display: flex;
	width: 100%;
	height: 100%;
}

.App-session-body-vote-completed-content-container {
	width: 50%;
	height: 70%;
	margin: auto;
	display: flex;
	flex-direction: column;
}

.App-session-body-vote-completed-content-image {
	margin: 0px auto;
	height: 40%;
}

.App-session-body-vote-completed-content-thank-you {
	margin: 10px auto;
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	color: #000000;
}

.App-session-body-vote-completed-content-voted-speaker {
	margin: 10px auto;
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 130%;
	text-align: center;
	letter-spacing: 0.3px;
	color: #444F5C;
}

